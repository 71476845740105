<template>
    <div>
        <banner banner-name="关于我"
                :background-img="$store.getters.getBackground('about')"
        ></banner>
        <v-card class="blog-container   an-blog-InUp">
            <div class="my-wrapper">
                <v-avatar size="110">
                    <img :src="blogInfo.user_image_url" alt="">
                </v-avatar>
            </div>
            <div class="about-content markdown-body" v-html="aboutContent"></div>
        </v-card>

    </div>
</template>

<script>
  import Banner from "../../components/banner/Banner";
  import {WebsiteInfoConfigMixin} from "../../components/mixin";

  export default {
    name: "About",
    mixins: [WebsiteInfoConfigMixin],
    data() {
      return {
        aboutContent: ""
      }
    },
    created() {
      this.aboutContent = this.blogInfo.about
    },
    watch: {
      aboutContent(val, newval) {
        if (val === '') {
          this.getAboutContent(newval)
        }
      }
    },

    methods: {
      getAboutContent(data) {
        let md = require('markdown-it')();
        this.aboutContent = md.render(data);
      },

    },
    components: {
      Banner
    }
  }
</script>

<style scoped>

    .blog-container {
        padding: 20px 50px 50px;
    }

    .my-wrapper {
        text-align: center;
    }

    .v-avatar {
        transition: all 0.5s;
    }

    .v-avatar:hover {
        transform: rotate(360deg);
    }
</style>
